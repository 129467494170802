import { useRouter } from '#app';
import { ref, onMounted } from 'vue';
import { createSharedComposable } from '@vueuse/core';

function useNavigationState() {
    const router = useRouter();
    const isClientSideNavigation = ref(false);

    const resetNavigationState = () => {
        isClientSideNavigation.value = false;
    };

    // Set up router hooks to update the navigation state
    onMounted(() => {
        // On client-side navigation
        router.beforeEach((to, from, next) => {
            // If it's a navigation to a different route
            if (to.path !== from.path) {
                isClientSideNavigation.value = true;
            }
            next();
        });

        // Handle component unmount to reset the flag if needed
        // This can help to determine a hard reload
        onBeforeRouteLeave(() => {
            resetNavigationState();
        });
    });

    return { isClientNavigation: computed(() => isClientSideNavigation.value), resetNavigationState };
}

export default createSharedComposable(useNavigationState);
