export const parseHTMLScript = htmlString => {
    let hasScript = false;
    let textContent = '';
    const attributes = {};
    if (htmlString) {
        try {
            const scriptRegex = /<script(?:\s+([^>]*))?\s*>([\s\S]*?)<\/script>/gi;
            const match = scriptRegex.exec(htmlString);

            if (!match) {
                throw new Error('No match!');
            }

            if (match[1]) {
                const attributeRegex = /\b(\w+)\s*=\s*(['"])(.*?)\2/g;
                let attrMatch;
                while ((attrMatch = attributeRegex.exec(match[1])) !== null) {
                    attributes[attrMatch[1]] = attrMatch[3];
                }
            }
            textContent = match[2].trim();
            hasScript = !!textContent || Object.keys(attributes ?? {}).length > 0;
        } catch (e) {
            console.error('could not parse script');
            hasScript = false;
        }
    }

    return { attributes, textContent, hasScript };
};
